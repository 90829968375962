
#root {
  background-color: #100f0f;
  min-height: 100vh;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  flex-direction: column;
  justify-content: space-between;
  white-space: pre-line;
  vertical-align: bottom;
}

#root::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 2;
  background-size: 100% 4px, 3px 100%;
	animation: flicker 1s infinite, glitch 0.02s infinite linear;
  pointer-events: none;
}

@keyframes glitch{
	100% { 
		top: 4px;
	}
}

@keyframes flicker {
  0% {
  opacity: 0.27861;
  }
  5% {
  opacity: 0.34769;
  }
  10% {
  opacity: 0.23604;
  }
  15% {
  opacity: 0.10626;
  }
  20% {
  opacity: 0.18128;
  }
  25% {
  opacity: 0.13891;
  }
  30% {
  opacity: 0.35583;
  }
  35% {
  opacity: 0.37807;
  }
  40% {
  opacity: 0.26559;
  }
  45% {
  opacity: 0.24693;
  }
  50% {
  opacity: 0.16019;
  }
  55% {
  opacity: 0.08594;
  }
  60% {
  opacity: 0.20313;
  }
  65% {
  opacity: 0.41988;
  }
  70% {
  opacity: 0.53455;
  }
  75% {
  opacity: 0.37288;
  }
  80% {
  opacity: 0.41428;
  }
  85% {
  opacity: 0.40419;
  }
  90% {
  opacity: 0.4003;
  }
  95% {
  opacity: 0.36108;
  }
  100% {
  opacity: 0.24387;
  }
}

a:hover{
 cursor:pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Preloader.css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.9); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.spinner {
  /* border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite; */
  width: 100%;
  height: 100%;
  background-image: url('https://www.flaticon.com/svg/static/icons/svg/3523/3523063.svg');
  background-size: 100px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
